<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <v-text-field
          :append-icon="icons.mdiMagnify"
          label="Buscar Vendedor"
          clearable
          outlined
          hide-details
          dense
          class="user-search mb-4"
          @click:append=""
          @click:clear=""
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="salesList"
      :loading="loading"
      loading-text="Carregando dados..."
      :page.sync="pageOptions.page"
      :items-per-page="pageOptions.itemsPerPage"
      :height="heightDinamic"
      hide-default-footer
      disable-sort
      fixed-header
      group-by="vendor_cpf"
      @page-count="pageCount = $event"
    >
      <!--data empty-->
      <template v-slot:no-data>
        {{ noDataText }}
      </template>

      <!--grouping-->
      <template v-slot:group.header="{items}">
        <td colspan="9">
          {{ items[0].vendor_cpf }}
        </td>
      </template>

      <!-- action (edit, delete, etc) -->
      <template
        #[`item.actions`]="{ item }"
      >
        <v-icon
          medium
          class="me-2"
          @click="getContractInfo(item)"
        >
          {{ icons.mdiFileCancelOutline }}
        </v-icon>
        <v-icon
          medium
          class="me-2"
          @click="getContractInfo(item)"
        >
          {{ icons.mdiDeleteOutline }}
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import {
  mdiAccountOutline,
  mdiAutorenew,
  mdiCalendar,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiEyeOutline,
  mdiFileCancelOutline,
  mdiFileDocumentOutline,
  mdiFilter,
  mdiFilterMenu,
  mdiFilterRemove,
  mdiLoading,
  mdiMagnify,
  mdiPencilOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiTextBoxSearch,
  mdiWhatsapp,
} from '@mdi/js'

export default {
  data: () => ({
    icons: {
      mdiSquareEditOutline,
      mdiDotsVertical,
      mdiPencilOutline,
      mdiFileDocumentOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiExportVariant,
      mdiAccountOutline,
      mdiFilter,
      mdiFilterRemove,
      mdiWhatsapp,
      mdiCalendar,
      mdiFilterMenu,
      mdiLoading,
      mdiEyeOutline,
      mdiMagnify,
      mdiTextBoxSearch,
      mdiAutorenew,
      mdiFileCancelOutline,
    },

    /* filter */
    filterOptions: {
      vendor_cpf: '',
    },

    /* data table */
    salesList: [],
    noDataText: 'Nenhum registro encontrado',
    heightDinamic: '400px',
    loading: false,
    headers: [
      { text: 'DT BORDERO', value: 'bordero_date' },
      { text: 'CONTRATO', value: 'contract_number' },
      { text: 'CLIENTE', value: 'client_name' },
      { text: 'MODELO', value: 'vehicle_model' },
      { text: 'COTA', value: 'quota_number' },
      { text: 'CARTA', value: 'letter' },
      { text: '%', value: 'percentage' },
      { text: 'COMISSÃO', value: 'commission' },

      { text: 'AÇÕES', value: 'actions', align: 'end' },
    ],

    /* router */
    routeOptions: {
      resource: 'index',
      method: 'get',
      params: {},
    },

    /* pagination */
    pageOptions: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
    },

    /* modal */
    modalOptions: {
      salesListItem: {},
    },
  }),

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.getSales()
    },

    /* data table methods */
    async getSales() {
      this.loading = true
      this.salesList = []
      await axiosIns
        .get('/api/v1/integration/cnh_sale/index')
        .then(res => {
          this.heightDinamic = `${window.screen.height * 0.45}px`
          res.data.data.map(value => {
            this.salesList.push({
              vendor_cpf: value.vendor_cpf,
              bordero_date: value.bordero_date,
              contract_number: value.contract_number,
              client_name: value.client_name,
              vehicle_model: value.vehicle_model,
              quota_number: value.quota_number,
              letter: value.letter,
              percentage: value.percentage,
              commission: value.commission,
            })
          })
          this.pageOptions.page = res.data.meta.current_page
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.noDataText = `Contate o suporte do sistema: ${err}`
        })
    },

    cancelSale() {},

    deleteSale() {},
  },
}
</script>
