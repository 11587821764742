<template>
  <v-card>
    <v-card-title>
      Escolha a competência
    </v-card-title>
    <v-container>
      <v-col
        cols="11"
        sm="11"
      >
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Selecione o período"
              :prepend-icon="icons.mdiCalendarMonthOutline"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            type="month"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <div
          class="d-flex flex-row-reverse bd-highlight"
        >
          <v-btn
            outlined
            color="success"
            @click="chooseCompetence()"
          >
            Confirmar
          </v-btn>
        </div>
      </v-col>
    </v-container>
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'
import { mdiCalendarMonthOutline } from '@mdi/js'

export default {
  props: {
    competenceId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    date: new Date().toISOString().substring(0, 7),
    menu: false,
    modal: false,
    dateCompetence: '',
    icons: {
      mdiCalendarMonthOutline,
    },
  }),

  methods: {
    async chooseCompetence() {
      await this.$swal({
        icon: 'warning',
        title: 'Atenção!',
        text: 'Esta ação atualizará a página!!!',
        showConfirmButton: true,
      })

      const body = {
        reference: `${this.date}-01`,
      }

      await axiosIns.put(`api/v1/integration/parameter/update/${this.competenceId}`, body)
        .then(() => {
          document.location.reload()
        })
    },
  },
}
</script>
