<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <v-text-field
          :append-icon="icons.mdiMagnify"
          label="Buscar Vendedor"
          clearable
          outlined
          hide-details
          dense
          class="user-search mb-4"
          @click:append=""
          @click:clear=""
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="commissionList"
      :loading="loading"
      loading-text="Carregando dados..."
      :page.sync="pageOptions.page"
      :items-per-page="pageOptions.itemsPerPage"
      :height="heightDinamic"
      hide-default-footer
      disable-sort
      fixed-header
      group-by="companies_id"
      @page-count="pageCount = $event"
    >
      <!--data empty-->
      <template v-slot:no-data>
        {{ noDataText }}
      </template>

      <!--grouping-->
      <template v-slot:group.header="{items}">
        <td colspan="10">
          {{ items[0].companies_id }}
        </td>
      </template>
    </v-data-table>
    <v-simple-table>
      <thead>
        <tr>
          <th>
            TOTAL
          </th>
          <th>
            ---
          </th>
          <th>
            ---
          </th>
          <th>
            ---
          </th>
          <th>
            ---
          </th>
          <th>
            ---
          </th>
        </tr>
      </thead>
    </v-simple-table>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import {
  mdiAccountOutline,
  mdiAutorenew,
  mdiCalendar,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiEyeOutline,
  mdiFileDocumentOutline,
  mdiFilter,
  mdiFilterMenu,
  mdiFilterRemove,
  mdiLoading,
  mdiMagnify,
  mdiPencilOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiTextBoxSearch,
  mdiWhatsapp,
} from '@mdi/js'

export default {
  data: () => ({
    icons: {
      mdiAccountOutline,
      mdiAutorenew,
      mdiCalendar,
      mdiDeleteOutline,
      mdiDotsVertical,
      mdiExportVariant,
      mdiEyeOutline,
      mdiFileDocumentOutline,
      mdiFilter,
      mdiFilterMenu,
      mdiFilterRemove,
      mdiLoading,
      mdiMagnify,
      mdiPencilOutline,
      mdiPlus,
      mdiSquareEditOutline,
      mdiTextBoxSearch,
      mdiWhatsapp,
    },

    /* filter */
    filterOptions: {
      vendor_cpf: '',
    },

    /* data table */
    commissionList: [],
    noDataText: 'Nenhum registro encontrado',
    heightDinamic: '400px',
    loading: false,
    headers: [
      { text: 'CodSelf', value: 'code_self' },
      { text: 'FUNCIONÁRIO', value: 'vendor_name' },
      { text: 'CRÉDITO', value: 'credit' },
      { text: 'COMISSION', value: 'commission' },
      { text: 'ESTORNO', value: 'estorn' },
      { text: 'FOLHA', value: 'total_commission' },
    ],

    /* router */
    routeOptions: {
      resource: 'index',
      method: 'get',
      params: {},
    },

    /* pagination */
    pageOptions: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
    },

    /* modal */
    modalOptions: {
      commissionListItem: {},
    },
  }),

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.getResumes()
    },

    /* data table methods */
    async getResumes() {
      this.loading = true
      this.commissionList = []
      await axiosIns
        .get('/api/v1/integration/cnh_commission/index')
        .then(res => {
          this.heightDinamic = `${window.screen.height * 0.45}px`
          res.data.data.map(value => {
            this.commissionList.push({
              vendor_cpf: value.vendor_cpf,
              code_self: value.code_self,
              commission: value.commission,
              credit: value.credit,
              estorn: value.estorn,
              total_commission: value.total_commission,
              companies_id: value.companies_id,
            })
          })
          this.pageOptions.page = res.data.meta.current_page
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.noDataText = `Contate o suporte do sistema: ${err}`
        })
    },
  },
}
</script>
