import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VTabs,{attrs:{"icons-and-text":""},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c(VTab,[_vm._v(" Vendas ")]),_c(VTab,[_vm._v(" Resumos ")]),_c(VTab,[_vm._v(" Estornos ")]),_c(VTab,[_vm._v(" Comissão ")]),_c(VSpacer),_c('div',{staticClass:"d-flex align-center pa-2"},[_c(VBtn,{staticClass:"mr-2",attrs:{"color":"secondary","loading":_vm.loadingButtonCompetence},on:{"click":function($event){return _vm.openModal()}}},[_vm._v(" Competência ")]),_c(VBtn,{staticClass:"pa-2",attrs:{"color":"primary","loading":_vm.loadingButton},on:{"click":_vm.commissionCalculation}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiCalculatorVariant))]),_vm._v(" CALCULAR ")],1)],1)],1),_c(VTabsItems,{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c(VTabItem,[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('sales-table')],1)],1)],1),_c(VTabItem,[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('resume-table')],1)],1)],1),_c(VTabItem,[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('reversal-table')],1)],1)],1),_c(VTabItem,[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('commission-table')],1)],1)],1)],1),_c(VDialog,{attrs:{"width":"400"},model:{value:(_vm.openCompetenceModal),callback:function ($$v) {_vm.openCompetenceModal=$$v},expression:"openCompetenceModal"}},[_c('competence-modal',{attrs:{"competence-id":_vm.competenceId}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }