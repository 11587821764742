<template>
  <div>
    <v-tabs
      v-model="currentTab"
      icons-and-text
    >
      <v-tab>
        Vendas
      </v-tab>

      <v-tab>
        Resumos
      </v-tab>

      <v-tab>
        Estornos
      </v-tab>

      <v-tab>
        Comissão
      </v-tab>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pa-2">
        <v-btn
          class="mr-2"
          color="secondary"
          :loading="loadingButtonCompetence"
          @click="openModal()"
        >
          Competência
        </v-btn>

        <v-btn
          class="pa-2"
          color="primary"
          :loading="loadingButton"
          @click="commissionCalculation"
        >
          <v-icon> {{ icons.mdiCalculatorVariant }}</v-icon>
          CALCULAR
        </v-btn>
      </div>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item>
        <v-card flat>
          <v-card-text><sales-table></sales-table></v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text><resume-table></resume-table></v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text><reversal-table></reversal-table></v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text><commission-table></commission-table></v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog
      v-model="openCompetenceModal"
      width="400"
    >
      <competence-modal
        :competence-id="competenceId"
      />
    </v-dialog>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import {
  mdiAccountBoxOutline, mdiCalculatorVariant, mdiHeartOutline, mdiPhone,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import commissionTable from './CommissionTable.vue'
import CompetenceModal from './CompetenceModal.vue'
import resumeTable from './ResumeTable.vue'
import reversalTable from './ReversalTable.vue'
import salesTable from './SalesTable.vue'

export default {
  components: {
    salesTable,
    resumeTable,
    reversalTable,
    commissionTable,
    CompetenceModal,
  },
  setup() {
    const currentTab = ref(null)
    const tabItemText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
      `
    const loadingButton = ref(false)

    const openCompetenceModal = false
    const competenceId = ''
    const loadingButtonCompetence = ref(false)

    return {
      currentTab,
      tabItemText,
      icons: {
        mdiPhone,
        mdiHeartOutline,
        mdiAccountBoxOutline,
        mdiCalculatorVariant,
      },
      loadingButton,
      openCompetenceModal,
      competenceId,
      loadingButtonCompetence,
    }
  },

  methods: {
    async openModal() {
      this.loadingButtonCompetence = true
      await axiosIns.get('api/v1/integration/parameter/index')
        .then(res => {
          this.competenceId = res.data.data[0].id
        })
      this.openCompetenceModal = true
      this.loadingButtonCompetence = false
    },
    commissionCalculation() {
      this.loadingButton = true
      axiosIns
        .get('/api/v1/integration/cnh_sale/commission_calculation')
        .then(response => {
          this.$swal({
            icon: 'success',
            title: 'Cálculo de comissão',
            showConfirmButton: false,
            text: `${response.data.data.mensage}`,
            timer: 4000,
          })
          setTimeout(() => {
            this.$router.go().reload()
          }, 4000)
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Cálculo de comissão',
            showConfirmButton: false,
            text: `Erro ao calcular comissão, contate o suporte do sistema: ${error}`,
          })
        })
        .finally(() => {
          this.loadingButton = false
        })
    },
  },
}
</script>
