<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <v-text-field
          :append-icon="icons.mdiMagnify"
          label="Buscar Vendedor"
          clearable
          outlined
          hide-details
          dense
          class="user-search mb-4"
          @click:append=""
          @click:clear=""
        >
        </v-text-field>
      </v-col>
      <v-spacer></v-spacer>

      <div class="d-flex align-center flex-wrap">
        <v-btn
          color="primary"
          class="mb-4 mx-3 pl-4"
          @click="openModal"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Novo</span>
        </v-btn>
      </div>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="reversalList"
      :loading="loading"
      loading-text="Carregando dados..."
      :page.sync="pageOptions.page"
      :items-per-page="pageOptions.itemsPerPage"
      :height="heightDinamic"
      hide-default-footer
      disable-sort
      fixed-header
      group-by="vendor_cpf"
      @page-count="pageCount = $event"
    >
      <!--data empty-->
      <template v-slot:no-data>
        {{ noDataText }}
      </template>

      <!--grouping-->
      <template v-slot:group.header="{items}">
        <td colspan="10">
          {{ items[0].vendor_cpf }}
        </td>
      </template>

      <!-- action (edit, delete, etc) -->
      <template #[`item.actions`]="{ item }">
        <v-icon
          medium
          class="me-2"
          @click="getContractInfo(item)"
        >
          {{ icons.mdiPencilOutline }}
        </v-icon>
        <v-icon
          medium
          class="me-2"
          @click="getContractInfo(item)"
        >
          {{ icons.mdiDeleteOutline }}
        </v-icon>
        <!-- <v-progress-circular
          v-if="item.id === loadModal? true : false"
          indeterminate
          :size="20"
          color="gray"
        ></v-progress-circular> -->
      </template>
    </v-data-table>

    <v-dialog
      v-model="modalOptions.modal"
      width="900"
    >
      <reversal-modal />
    </v-dialog>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import {
  mdiAccountOutline,
  mdiAutorenew,
  mdiCalendar,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiEyeOutline,
  mdiFileDocumentOutline,
  mdiFilter,
  mdiFilterMenu,
  mdiFilterRemove,
  mdiLoading,
  mdiMagnify,
  mdiPencilOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiTextBoxSearch,
  mdiWhatsapp,
} from '@mdi/js'

export default {
  data: () => ({
    icons: {
      mdiAccountOutline,
      mdiAutorenew,
      mdiCalendar,
      mdiDeleteOutline,
      mdiDotsVertical,
      mdiExportVariant,
      mdiEyeOutline,
      mdiFileDocumentOutline,
      mdiFilter,
      mdiFilterMenu,
      mdiFilterRemove,
      mdiLoading,
      mdiMagnify,
      mdiPencilOutline,
      mdiPlus,
      mdiSquareEditOutline,
      mdiTextBoxSearch,
      mdiWhatsapp,
    },

    /* filter */
    filterOptions: {
      vendor_cpf: '',
    },

    /* data table */
    reversalList: [],
    noDataText: 'Nenhum registro encontrado',
    heightDinamic: '400px',
    loading: false,
    headers: [
      { text: 'DT BORDERO', value: 'date_bordero' },
      { text: 'CANCELADO', value: 'canceled' },
      { text: 'CONTRATO', value: 'contract_number' },
      { text: 'CLIENTE', value: 'client_name' },
      { text: 'MODELO', value: 'vehicle_model' },
      { text: 'CRÉDITO', value: 'credit_value' },
      { text: '%', value: 'percentage' },
      { text: 'ESTORNO', value: 'reversal' },
      { text: 'AÇÕES', value: 'actions', align: 'end' },
    ],

    /* router */
    routeOptions: {
      resource: 'index',
      method: 'get',
      params: {},
    },

    /* pagination */
    pageOptions: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
    },

    /* modal */
    modalOptions: {
      reversalListItem: {},
    },
  }),

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.getReversals()
    },

    /* data table methods */
    async getReversals() {
      this.loading = true
      this.reversalList = []
      await axiosIns
        .get('/api/v1/integration/cnh_chargeback/index')
        .then(res => {
          this.heightDinamic = `${window.screen.height * 0.45}px`
          res.data.data.map(value => {
            this.reversalList.push({
              vendor_cpf: value.vendor_cpf,
              contract_number: value.contract_number,
              client_name: value.client_name,
              vehicle_model: value.vehicle_model,
              credit_value: value.credit_value,
              percentage: value.percentage,
            })
          })
          this.pageOptions.page = res.data.meta.current_page
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.noDataText = `Contate o suporte do sistema: ${err}`
        })
    },

    cancelReversal() {},

    deleteReversal() {},
  },
}
</script>
