<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <v-text-field
          :append-icon="icons.mdiMagnify"
          label="Buscar Vendedor"
          clearable
          outlined
          hide-details
          dense
          class="user-search mb-4"
          @click:append=""
          @click:clear=""
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="resumeList"
      :loading="loading"
      loading-text="Carregando dados..."
      :page.sync="pageOptions.page"
      :items-per-page="pageOptions.itemsPerPage"
      :height="heightDinamic"
      hide-default-footer
      disable-sort
      fixed-header
      group-by="vendor_cpf"
      @page-count="pageCount = $event"
    >
      <!--data empty-->
      <template v-slot:no-data>
        {{ noDataText }}
      </template>

      <!--grouping-->
      <template v-slot:group.header="{items}">
        <td colspan="10">
          {{ items[0].vendor_cpf }}
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import {
  mdiAccountOutline,
  mdiAutorenew,
  mdiCalendar,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiEyeOutline,
  mdiFileDocumentOutline,
  mdiFilter,
  mdiFilterMenu,
  mdiFilterRemove,
  mdiLoading,
  mdiMagnify,
  mdiPencilOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiTextBoxSearch,
  mdiWhatsapp,
} from '@mdi/js'

export default {
  data: () => ({
    icons: {
      mdiAccountOutline,
      mdiAutorenew,
      mdiCalendar,
      mdiDeleteOutline,
      mdiDotsVertical,
      mdiExportVariant,
      mdiEyeOutline,
      mdiFileDocumentOutline,
      mdiFilter,
      mdiFilterMenu,
      mdiFilterRemove,
      mdiLoading,
      mdiMagnify,
      mdiPencilOutline,
      mdiPlus,
      mdiSquareEditOutline,
      mdiTextBoxSearch,
      mdiWhatsapp,
    },

    /* filter */
    filterOptions: {
      vendor_cpf: '',
    },

    /* data table */
    resumeList: [],
    noDataText: 'Nenhum registro encontrado',
    heightDinamic: '400px',
    loading: false,
    headers: [
      { text: 'META', value: 'goal' },
      { text: 'COTA', value: 'quota_number' },
      { text: 'CRED COTA', value: 'quota_credit' },
      { text: 'CRED CARTA', value: 'letter_credit' },
      { text: 'COMISSÃO CARTA', value: 'commission_letter' },
      { text: '0,8%', value: 'commission_08' },
      { text: '1,0%', value: 'commission_10' },
      { text: '1,3%', value: 'commission_13' },
      { text: 'TOTAL', value: 'total' },
    ],

    /* router */
    routeOptions: {
      resource: 'index',
      method: 'get',
      params: {},
    },

    /* pagination */
    pageOptions: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
    },

    /* modal */
    modalOptions: {
      resumeListItem: {},
    },
  }),

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.getResumes()
    },

    /* data table methods */
    async getResumes() {
      this.loading = true
      this.resumeList = []
      await axiosIns
        .get('/api/v1/integration/cnh_apuration/index')
        .then(res => {
          this.heightDinamic = `${window.screen.height * 0.45}px`
          res.data.data.map(value => {
            this.resumeList.push({
              vendor_cpf: value.vendor_cpf,
              goal: value.goal,
              quota_number: value.sales,
              quota_credit: value.quota_credit,
              letter_credit: value.letter_credit,
              commission_letter: value.commission_letter,
              commission_08: value.commission_08,
              commission_10: value.commission_10,
              commission_13: value.commission_13,
            })
          })
          this.pageOptions.page = res.data.meta.current_page
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.noDataText = `Contate o suporte do sistema: ${err}`
        })
    },
  },
}
</script>
